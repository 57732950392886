import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Text, translate } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import { ButtonLink } from "../../../shared/Buttons";
import { Spinner } from "../../../shared/Spinner";
import { ReactComponent as Timer } from "../../../assets/icons/timer-secondary.svg";

const EstimatedDeliveryDateCard = ({
  guestKey,
  shipping,
  loading,
  isShippingDelivering,
  isShippingDelivered,
  language,
}) => {
  /*   const formatTimeData = (timeData) => {
    const timeDataSplitted = timeData?.split("-");
    let dataStart = timeDataSplitted[0].trim();
    let dataEnd = timeDataSplitted[1].trim();
    if (dataStart.length < 3) {
      dataStart = dataStart + ":00";
    }
    if (dataEnd.length < 3) {
      dataEnd = dataEnd + ":00";
    }
    return isShippingDelivered
      ? `${translate("betweenHours", language)} ${dataStart} ${translate(
          "andHours",
          language
        )} ${dataEnd}`
      : `${translate("fromHours", language)} ${dataStart} ${translate(
          "toHours",
          language
        )} ${dataEnd}`;
  };
 */
  return (
    <>
      <Card
        className="card"
        paddingTopContainer="0"
        paddingCard="16px 24px 24px 24px"
        backgroundColor="var(--primary)"

        /*  cardTitle={<Text label="prenotazione" />} */
        // cardJustifyContent={
        //   !isShippingDelivering() && "space-between"
        // }
      >
        {loading ? (
          <Spinner />
        ) : (
          // ) : !shipping?.estimatedDeliveryDate ? (

          <div
            style={{
              display: "flex",
              justifyContent: !isShippingDelivering
                ? "space-between"
                : "flex-start",
              alignItems: "center",
              height: "100%",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Timer
              width={56}
              height={56}
              style={{
                marginRight: 8,
              }}
            />
            {shipping.serviceCode === "R" ? (
              <h3 className="mb-05 mt-1">
                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: "0.8em",
                    color: "#fff",
                    textAlign: "center",
                  }}
                  label="resoPrevisto"
                />
              </h3>
            ) : (
              <h3 className="mb-05 mt-1">
                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: "0.8em",
                    color: "#fff",
                  }}
                  label="consegnaPrevista"
                />
              </h3>
            )}
            <div>
              <div
                className="mb-05 mt-05"
                style={{
                  fontSize: "2rem",
                  color: "var(--secondary)",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {/* {shipping?.estimatedDeliveryDate ? (
                        moment(shipping.estimatedDeliveryDate).format( */}
                {shipping?.estimatedDeliveryDate ? (
                  <div>
                    {moment(shipping?.estimatedDeliveryDate).format(
                      "DD MMMM YYYY "
                    )}
                  </div>
                ) : (
                  <Text
                    style={{ fontSize: "1.1rem" }}
                    label="dataConsegnaNonDisponibile"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};
/* const EstimatedDeliveryDateCard = ({
  guestKey,
  shipping,
  loading,
  isShippingDelivering,
  isShippingDelivered,
  language,
}) => {
  const formatTimeData = (timeData) => {
    const timeDataSplitted = timeData?.split("-");
    let dataStart = timeDataSplitted[0].trim();
    let dataEnd = timeDataSplitted[1].trim();
    if (dataStart.length < 3) {
      dataStart = dataStart + ":00";
    }
    if (dataEnd.length < 3) {
      dataEnd = dataEnd + ":00";
    }
    return isShippingDelivered
      ? `${translate("betweenHours", language)} ${dataStart} ${translate(
          "andHours",
          language
        )} ${dataEnd}`
      : `${translate("fromHours", language)} ${dataStart} ${translate(
          "toHours",
          language
        )} ${dataEnd}`;
  };

  return (
    <>
      <Card
        className="card"
        paddingTopContainer="0"
        paddingCard="16px 24px 24px 24px"
        backgroundColor="var(--primary)"

        // cardJustifyContent={
        //   !isShippingDelivering() && "space-between"
        // }
      >
        {loading ? (
          <Spinner />
        ) : // ) : !shipping?.estimatedDeliveryDate ? (
        shipping?.events <= 1 ? (
          // <div>
          <div
            style={{
              alignItems: "center",
              minHeight: 192,
            }}
            className="flex-row-center"
          >
            <Text
              style={{ fontSize: 18, color: "#fff" }}
              label="ConsegnaNonInCarico"
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: !isShippingDelivering
                ? "space-between"
                : "flex-start",
              alignItems: "center",
              height: "100%",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Timer width={56} height={56} style={{ marginRight: 8 }} />
            <h2 className="mb-05">
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: "0.9em",
                  color: "#fff",
                }}
                label="prenotazione"
              />
            </h2>
            <div>
              <div>
                <Text style={{ color: "white" }} label="prenotazionePrevista" />
              </div>
              <div className="deliveryDate">
                {shipping?.booking?.bookingDate ? (
                  <div style={{ color: "white" }}>
                    {moment(shipping?.booking?.bookingDate).format(
                      "DD MMMM YYYY "
                    )}
                    <br />
                    {formatTimeData(shipping?.booking?.slotTimeRange)}
                  </div>
                ) : (
                  <Text
                    style={{ fontSize: "1.1rem", color: "#fff" }}
                    label="dataConsegnaNonDisponibile"
                  />
                )}
              </div>
            </div>
            {!isShippingDelivering &&
              shipping?.serviceCode?.toLowerCase() !== "r" && (
                <div>
                  <Text
                    style={{
                      fontWeight: 300,
                      color: "#fff",
                    }}
                    className="light-weight"
                    label="prenotazionePersonalizzata"
                  />
                  <div className="flex-row-center">
                    <Link
                      className="link-style"
                      to={
                        guestKey
                          ? {
                              pathname: "/booking",
                            }
                          : {
                              pathname: "/richiesta-link",
                              state: {
                                action: "BOOKING",
                                title: "richiestaPianifica",
                              },
                            }
                      }
                    >
                      <ButtonLink backgroundColor="#fff" marginTop="12px">
                        <Timer
                          width={24}
                          height={24}
                          style={{
                            marginRight: 8,
                            stroke: "var(--primary)",
                          }}
                        />
                        <Text
                          style={{ color: "var(--primary)" }}
                          label="pianifica"
                        />
                      </ButtonLink>
                    </Link>
                  </div>
                </div>
              )}
          </div>
        )}
      </Card>
    </>
  );
};
 */
export default EstimatedDeliveryDateCard;
